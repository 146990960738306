class GadgetDataTransformer {

  transform(gadgetState) {
    return this._cleanObject({
      departures: gadgetState.departures ? this._removeEmpties(gadgetState.departures.map(departure => departure.value)) : undefined,
      destination: gadgetState.destination.id,
      departureDate: gadgetState.departureDate,
      nights: gadgetState.nights,
      adults: gadgetState.adults,
      children: gadgetState.children ? gadgetState.children.map(child => child.value) : undefined,
      boardBasis: gadgetState.boardBasis,
      starRating: gadgetState.starRating
    })
  }

  _cleanObject(unclean) {
    let cleaned = {}
    Object.keys(unclean).forEach((key) => {
      if (unclean[key]) {
        cleaned[key] = unclean[key]
      }
    });
    return cleaned
  }

  _removeEmpties(list) {
    return list.filter(item => item !== '')
  }
}

const gadgetDataTransformer = new GadgetDataTransformer()
export default gadgetDataTransformer