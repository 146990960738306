'use strict'

import Vue from 'vue'
import targetDataBuilder from './TargetDataBuilder';

export default {
    state: {
        disableAds: false,
        dataSlot: '/3132/tsm/',
        adInstances: {
            'slot-1-all': 'leader1',
            'slot-2-desktop': 'right1',
            'slot-3-mobile': 'all2',
            'slot-4-all': 'all3',
            'slot-5-all': 'all4',
            'slot-6-hero': 'all1',
            'slot-7-campaign': 'all5',
            'slot-8-campaign': 'all6',
            'slot-9-desktop': 'right2'
        },
        adSizes: {
            'slot-1-all': [
                [728, 90],
                [300, 50],
                [320, 50],
                [320, 75],
                [300, 75]
            ],
            'slot-2-desktop': [[160, 600], [300, 600], [300, 250]],
            'slot-3-mobile': [[300, 250]],
            'slot-4-all': [
                [970, 250],
                [728, 90],
                [300, 50],
                [320, 50],
                [320, 75],
                [300, 75]
            ],
            'slot-5-all': [[728, 90], [300, 50], [320, 50], [320, 75], [300, 75]],
            'slot-6-hero': [[1, 3]],
            'slot-7-campaign': [[1, 4]],
            'slot-8-campaign': [[1, 5]],
            'slot-9-desktop': [[160, 600], [300, 600], [300, 250]]
        },
        mappings: {
            'slot-1-all': {
                desktop: [[728, 90]],
                mobile: [[300, 50], [320, 50], [320, 75], [300, 75]]
            },
            'slot-2-desktop': {
                desktop: [[160, 600], [300, 600], [300, 250]],
                mobile: []
            },
            'slot-3-mobile': {
                desktop: [],
                mobile: [[300, 250]]
            },
            'slot-4-all': {
                desktop: [[970, 250], [728, 90]],
                mobile: [[300, 50], [320, 50], [320, 75], [300, 75]]
            },
            'slot-5-all': {
                desktop: [[728, 90]],
                mobile: [[300, 50], [320, 50], [320, 75], [300, 75]]
            },
            'slot-6-hero': {
                desktop: [[1, 3]],
                mobile: []
            },
            'slot-7-campaign': {
                desktop: [[1, 4]],
                mobile: [[1, 4]]
            },
            'slot-8-campaign': {
                desktop: [[1, 5]],
                mobile: [[1, 5]]
            },
            'slot-9-desktop': {
                desktop: [[160, 600], [300, 600], [300, 250]],
                mobile: []
            }
        },
        advertData: null
    },
    mutations: {
        disableAds: (state, payload) => state.disableAds = payload,
        advertData: (state, payload) => {
            payload = payload || {pageType: 'destination'}
            if (!payload.pageType) { 
                payload.pageType = 'destination'
            }
            Vue.set(state, 'advertData', payload)
        }
    },
    actions: {
        loadAd({ state, rootState, dispatch }, adId) {
            window.googletag.cmd.push(() => {
                const mapping = window.googletag
                    .sizeMapping()
                    .addSize([0, 0], []) // No ad shown
                    .addSize([320, 1], state.mappings[adId].mobile) // Mobile
                    .addSize([1024, 1], state.mappings[adId].desktop) // Desktop
                    .build();

                window.googletag.defineSlot(`${state.dataSlot}${rootState.components.cleanChannelName}/${state.advertData.pageType}`, state.adSizes[adId], adId)
                .setTargeting('ad_position', targetDataBuilder.isInViewPort(adId) ? 'atf' : 'btf')
                .setTargeting('ad_instance', state.adInstances[adId])
                .defineSizeMapping(mapping)
                .addService(window.googletag.pubads());

                dispatch('setTargeting', state)

                window.googletag.pubads().enableLazyLoad();
                window.googletag.pubads().enableSingleRequest();
                window.googletag.companionAds().setRefreshUnfilledSlots(true);
                window.googletag.enableServices();

                window.googletag.display(adId);
            });
        },
        setTargeting({ state, rootState }) {
            const targetting = targetDataBuilder.getTargetData(state.advertData, rootState.components.cleanChannelName)
            for (const key in targetting) {
                window.googletag.pubads().setTargeting(key, targetting[key])
            }
        }
    },
    getters: {
        advertData: (state) => state.advertData,
        disableAds: (state) => state.disableAds
    }
}
