'use strict'

import Vue from 'vue'

export default {
    state: {
        listings: {},
        listingsUrl: 'https://api.travelsupermarket.com/global/listing/v1/listings/'
    },
    getters: { listings: state => id => state.listings[id] },

    mutations: {
        listings: (state, payload) => Vue.set(state.listings, payload.id, payload.listings)
    },
    actions: {
        async fetchListings({ commit, state }, payload) {
            const axios = await import(/* webpackChunkName: "tsm-axios" */ "axios")
            return axios.get(`${state.listingsUrl}${payload.id}/items`)
                .then(response => {
                    if (response && response.data && response.data.length > 0) {
                        commit('listings', { id: payload.id, listings: response.data })
                    }
                }).catch(() => {
                    //Do nothing, we just need to handle the error and continue.
                })
        }
    }
}
