'use strict';

class ComponentLazyLoader {

    install(Vue) {

        try {
            if (!global.window) {
                Vue.prototype.$observeComponent = () => {
                    // Do nothing for server-side rendering.
                    return;
                }
                return;
            }
        } catch (error) {
            // Don't do anything with the error as it's been thrown client side, carry on setting up the observeComponent.
        }
        
        if (window['IntersectionObserver']) {
            Vue.prototype.$observeComponent = (element, callback) => {
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            callback();
                            observer.unobserve(element);
                        }
                    })
                });
                observer.observe(element);
            };
        }
    }
}

export default new ComponentLazyLoader();
