'use strict'
import HelperFunctions from '../../../HelperFunctions'

const departuresMap = require("../../../resources/departures");

const isoDateToClickTripzDateFormat = (isoDate) => {
    const [yyyy, mm, dd] = isoDate.split('-');
    return [mm, dd, yyyy].join('/');
  };
  
const clickTripzDateToIsoDateFormat = (clickTripzDate) => {
  const [mm, dd, yyyy] = clickTripzDate.split('/');
  return [yyyy, mm, dd].join('-');
};

function getAirportIATAByValue(value) {
  for (const category in departuresMap) {
    if (departuresMap.hasOwnProperty(category)) {
      const entries = departuresMap[category];
      const entry = entries.find((entry) => entry.value === value);
      if (entry) {
        return entry.iata;
      }
    }
  }
  return null;
}

function addDaysToDate(dateString, daysToAdd) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
}

const calculateIsIntervalPassed = (
    previousTimestamp,
    currentTimestamp,
    interval,
    delay = 0
  ) => {
    return currentTimestamp + delay >= previousTimestamp + interval;
};

const getBackTabClickTripzAdvertisersQueryParams = (
    params,
    desktopPlacementId,
    mobilePlacementId,
    siteId
  ) => {
    return new URLSearchParams({
      placementId: params.layout === 'mobile' ? mobilePlacementId : desktopPlacementId,
      siteId,
      destination: params.searchState.destination.name,
      departures: params.searchState.departures.map((departure) => getAirportIATAByValue(departure.value)).join(','),
      startDate: isoDateToClickTripzDateFormat(params.searchState.departureDate),
      endDate: isoDateToClickTripzDateFormat(addDaysToDate(params.searchState.departureDate, params.searchState.nights)),
      nights: params.searchState.nights,
      adults: params.searchState.adults,
    });
};

const fetchClickTripzAdvertisersByUrl = async (url) => {
    try {
      const response = await fetch(url);
      const responseText = await response.text();
      const parsedResponse = JSON.parse(responseText.slice(1, -1));        
      if (parsedResponse.data && parsedResponse.data.advertisers) {
        return parsedResponse.data.advertisers;
      }
      return [];
    } catch (e) {
      return [];
    }
  };

  const getQueryParamsWithSessionIdAndPageRequestId = () => {
    const pageRequestId = HelperFunctions.readCookie('pageRequestId');
    const sessionId = HelperFunctions.readCookie('web-session-id');
    const queryParams = new URLSearchParams();
    if (pageRequestId) {
      queryParams.set('pageRequestId', pageRequestId);
    } else {
      console.warn('page-request-id not found in cookie 🍪');
    }
    if (sessionId) {
      queryParams.set('sessionId', sessionId);
    } else {
      console.warn('session-id not found in cookie 🍪');
    }
    return queryParams;
  }; 

  const getClickTripzAdQueryParams = (
    siteId,
    searchParams,
    layout,
    cookiesQueryParams
  ) => {    
    const destinationCode = [
      searchParams.destinationCountry,
      searchParams.destinationArea,
      searchParams.destinationRegion,
    ]
      .filter((code) => !!code)
      .join('-');

    const queryParams = new URLSearchParams({
      ...Object.fromEntries(cookiesQueryParams),
      site: siteId,
      device: layout.replace('-xs','').replace('-xl', ''),
      adults: searchParams.get("adults"),
      childAges: searchParams.get("childAges") ? searchParams.get("childAges").join(',') : 0,
      code: destinationCode,
      departureAirports: searchParams.get("departures"),
      duration: searchParams.get("nights"),
      startDate: clickTripzDateToIsoDateFormat(searchParams.get("startDate")),
    });

    if (searchParams.destinationId)
      queryParams.set('placeKey', searchParams.destinationId);
    return queryParams;
  };


export default {
    state: {
        backTabConfig: {
            clickTripzApi: 'https://www.clicktripz.com/x/pas',
            desktopPlacementId: '3833-1',
            mobilePlacementId: '3928-1',
            siteId: 'icelolly', // no account set up for TSM
            timestampCookieKey: 'travelsupermarket-back-tab-timestamp',
            testApiHost: 'https://api.staging.icelolly.com/advertising',
            prodApiHost: 'https://api.icelolly.com/advertising',        
        },
    },
    actions: {
        async loadClickTripzAdSettings ({ state }, params) {
          const searchParams = getBackTabClickTripzAdvertisersQueryParams(
            params,
            state.backTabConfig.desktopPlacementId,
            state.backTabConfig.mobilePlacementId,
            state.backTabConfig.siteId
          );          
          if (!searchParams) return;
      
          try {
            const queryParams = getClickTripzAdQueryParams(
              state.backTabConfig.siteId,
              searchParams,
              params.layout,
              getQueryParamsWithSessionIdAndPageRequestId()
            );

            const response = await fetch(`${window.location.href.includes("www.travelsupermarket") ? state.backTabConfig.prodApiHost : state.backTabConfig.testApiHost}/v1/backtab?${queryParams.toString()}`);
            if (!response.ok) throw response;
            const settings = await response.json();
            state.backTabSettings = settings;
            return settings;
          } catch (e) {
            return;
          }
        },

        async redirectWithBackTab({ state }, params) {
            const previousTimestamp = Number(localStorage.getItem(state.backTabConfig.timestampCookieKey)) || 0;

            if (
                !state.backTabSettings ||
                !state.backTabSettings.metadata.enabled ||
                !calculateIsIntervalPassed(
                  previousTimestamp,
                  Date.now(),
                  state.backTabSettings.metadata.interval
                ) ||
                !window.open(params.resultsUrl, '_blank')
              ) {
                window.location.assign(params.resultsUrl);
                return;
              }

            const queryParams = getBackTabClickTripzAdvertisersQueryParams(
                params,
                state.backTabConfig.desktopPlacementId,
                state.backTabConfig.mobilePlacementId,
                state.backTabConfig.siteId                
            );

            const advertisers = await fetchClickTripzAdvertisersByUrl(
              `${state.backTabConfig.clickTripzApi}?${queryParams.toString()}`
            );
            if (advertisers.length > 0) {
              localStorage.setItem(
                state.backTabConfig.timestampCookieKey,
                `${Date.now()}`
              );
              window.location.assign(advertisers[0].link);
            }
        }
    }
}