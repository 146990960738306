'use strict'

import Vue from 'vue'
import HelperFunctions from "@/HelperFunctions";

export default {
    state: {
        components: [],
        imagesUrl: '/sonic/image/',
        page: null,
        channel: null,
        cleanChannelName: null
    },
    getters:{
        components: (state) => state.components,
        imagesUrl: (state) => state.imagesUrl.concat(`${state.channel}-${state.page}`),
        page: state => state.page,
        channel: state => state.channel,
        cleanChannelName: state => state.cleanChannelName
    },
    mutations: {
        components: (state, payload) => Vue.set(state, 'components', payload),
        setPage : (state, payload) => Vue.set(state, 'page', payload),
        setChannel : (state, payload) => Vue.set(state, 'channel', payload),
        setCleanChannelName: (state, payload) => Vue.set(state, 'cleanChannelName', payload)
    },
    actions: {
        cleanChannelName({ commit, state }) {
            return Promise.all([]).then(() => commit('setCleanChannelName', HelperFunctions.removeDashes(state.channel).toLowerCase()))
        }
    }
}
