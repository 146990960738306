const departuresMap = require("../../../resources/departures.json");

class DestinationFinderUrlGenerator {
  constructor() {
    this.baseUrl = `/en-gb/holidays/find-your-holiday`;
  }

  generate(gadgetData) {
    const queryParams = new URLSearchParams({
        airports: this._constructDepartureAirports(gadgetData.departures),
        date: gadgetData.departureDate,
        duration: gadgetData.nights,
        passengers: this._constructPax(gadgetData.adults, gadgetData.children),
    });

    return `${this.baseUrl}/?${queryParams.toString()}`;
  }

  _constructDepartureAirports(departures) {
    const iataArr = [];
    departures.forEach((id) => {
      const region = departuresMap.regions.find(({ value }) => id === value);
      const airport = departuresMap.airports.find(({ value }) => id === value);
      if (region) {
        iataArr.push(...region.iataGroup);
      } else if (airport) {
        iataArr.push(airport.iata);
      }
    });
    const noDuplicates = [...new Set(iataArr)];
    return noDuplicates.join("+");
  }

  _constructPax(adults, children) {
    const arr = [];
    if (adults) {
      arr.push(adults);
    }
    if (children) {
      arr.push(...children);
    }
    return arr.join("+");
  }
}

const destinationFinderUrlGenerator = new DestinationFinderUrlGenerator();
export default destinationFinderUrlGenerator;
