class ResultsUrlGenerator {
  constructor() {
    this.baseUrl = `/en-gb/holidays/results`
  }

  generate(gadgetData) {
    return `${this.baseUrl}/${gadgetData.departures.join(':')}/${gadgetData.destination}/${gadgetData.departureDate}/${gadgetData.nights}/${this._constructPax(gadgetData.adults, gadgetData.children)}${this._constructListQueryParam('f_star', gadgetData.starRating)}${this._constructListQueryParam('f_boardAndCatering', gadgetData.boardBasis)}`
  }
  
  _constructListQueryParam(paramName, list) {
    if (list && list.length > 0) {
      return `&${paramName}=${list.join(',')}`
    }
    return ''
  }

  _constructPax(adults, children) {
    if (adults && children) {
      return `?room=A${adults}${children.map(childAge => `:C${childAge}`).join('')}`
    } else if(adults && !children) {
      return `?room=A${adults}`
    }
    return ''
  }
}

const resultsUrlGenerator = new ResultsUrlGenerator()
export default resultsUrlGenerator
