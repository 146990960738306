'use strict'
import Constants from "./Constants";

export default class HelperFunctions {

    static createCookie(name, value, expiryDays, path='/', domain) {
        let expires = ''
        if (expiryDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
            expires = ';expires=' + date.toGMTString();
        }
        let domainMeta = domain ? `;domain=${domain}`: ''
        document.cookie = `${name}=${value}${expires}${domainMeta};path=${path}`
    }

    static readCookie(key) {
        const regexp = new RegExp(`.*${key}=([^;]*)`);
        const result = regexp.exec(document.cookie);
        if (result) {
            return result[1];
        }
    }

    static readCookiesWithPrefix(prefix) {
        const values = document.cookie.split(';').filter(value => value.trim().startsWith(prefix))
        return values.map(value => {
            const splitValues = value.split('=')
            return {name: splitValues[0].trim(), value: splitValues[1].trim()}
        })
    }

    static cleanUrl(url) {
        if (url) {
            let cleanedUrl = url
            if (url[0] === '/') {
                cleanedUrl = url.slice(1)
            }
            if (url[url.length - 1] === '/') {
                cleanedUrl = cleanedUrl.slice(0, cleanedUrl.length - 1)
            }
            return cleanedUrl
        }
         
    }

    static removeSpaces(str) {
        return str ? str.replace(/ /g, '') : ''
    }

    static removeDashes(str) {
        return str ? str.replace(/-/g, '') : ''
    }

    static dateParseNowToUrlFriendly(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getUTCFullYear();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    }

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static parseToISODate(str) {
      //convert '01 Aug 19' to '2019-08-01'
      const strParts = str.split(" ");
      const partsDay = strParts[0];
      const partsMonth = Constants.MONTHS.indexOf(strParts[1]) + 1;
      const partsYear = strParts[2];
      //need to do this for IE10. If you're reading this in the 22nd Century, this is the line you're probably looking for
      const newDate = `20${partsYear}-${
        partsMonth < 10 ? "0" : ""
      }${partsMonth}-${partsDay < 10 ? "0" : ""}${partsDay}`;

      const date = new Date(newDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getUTCFullYear();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    }

    static dateParseToReadable(str) {
      //convert '2019-08-01' to '01 Aug 19'
      const date = new Date(str);
      const day = date.getUTCDate();
      const month = Constants.MONTHS[date.getUTCMonth()];
      const year = date
        .getUTCFullYear()
        .toString()
        .substr(2);
      return `${day} ${month} ${year}`;
    }

    static dateParseHourReadable(str) {
      //return hour as XXam/pm from date
      const date = new Date(str);
      const hours = date.toLocaleString('en-GB', {hour: 'numeric', hour12: true});
      return hours.replace(/\s/g, "");
    }

    static dateIncrement(days) {
        const now = new Date();
        now.setDate(now.getDate() + days);
        return now;
    }

    static getNumberOfDays(start, end) {
        const date1 = new Date(start);
        const date2 = new Date(end);
    
        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24;
    
        // Calculating the time difference between two dates
        const diffInTime = date2.getTime() - date1.getTime();
    
        // Calculating the no. of days between two dates
        const diffInDays = Math.round(diffInTime / oneDay);
    
        return diffInDays;
    }

    static getCurrentDateTime() {
      //  output: YYYYMMDDHHMI
      const today = new Date();
      const date =
        today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate();
      const time = today.getHours() + "" + today.getMinutes();
      return date + "" + time;
    }
    
    static isProd() {
        return window.location.hostname === 'www.travelsupermarket.com' ? true : false
    }
}
