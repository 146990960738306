'use strict'

export default {
    state: {
        signUpComplete: false
    },
    mutations: {
        signUpComplete(state, payload) {
            state.signUpComplete = payload
        }
    },
    actions: {
        signUpComplete: ({ commit }) => commit('signUpComplete', true)
    },
    getters: {
        signUpComplete: (state) => state.signUpComplete
    }
}