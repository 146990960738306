import HelperFunctions from '@/HelperFunctions'

class EnquiryUrlGenerator {
  constructor(helperFunctions) {
    this.helperFunctions = helperFunctions
    this.baseUrl = `https://api.travelsupermarket.com/global/public-carhire-agg/v1/search`
    const date = new Date()
    date.setDate(date.getDate() + 7)
    this.pickUpDate = helperFunctions.dateParseNowToUrlFriendly(date)
    date.setDate(date.getDate() + 7)
    this.dropOffDate = helperFunctions.dateParseNowToUrlFriendly(date)
  }

  generate(data) {
    return `${this.baseUrl}/${data.id}/${data.id}/${this.pickUpDate}T10:00/${this.dropOffDate}T10:00/33?userId=ch-deals-component`
  }
}

const enquiryUrlGenerator = new EnquiryUrlGenerator(HelperFunctions)
export default enquiryUrlGenerator
