import resultsUrlGenerator from './ResultsUrlGenerator'
import gadgetDataTransformer from './GadgetDataTransformer'
import { storageProxy } from '../../../StorageProxy'
import ABService from "@/ABService"

export default {
    state: {
        pickUpLocation: null,
        pickUpDate: '',
        pickUpTime: '10:00',
        returnToSameLocation: true,
        dropOffLocation: null,
        dropOffDate: '',
        dropOffTime: '10:00',
        driverAge: 33,
        carHireValidationErrors: {},
        carHireResultsUrl: null
    },
    mutations: {
        validateCarHireGadget(state) {
            //Reset previous validation errors
            state.carHireValidationErrors = {}

            if (!state.pickUpLocation || !state.pickUpLocation.id || Object.keys(state.pickUpLocation).length === 0) {
                state.carHireValidationErrors['pickUpLocation'] = 'Please enter a location'
            }

            if (!state.returnToSameLocation && (!state.dropOffLocation || !state.dropOffLocation.id || Object.keys(state.dropOffLocation).length === 0)) {
                state.carHireValidationErrors['dropOffLocation'] = 'Please enter a location'
            }

            if (!state.pickUpDate || new Date(state.pickUpDate) <= new Date()) {
                state.carHireValidationErrors['pickUpDate'] = 'No start date'
            }

            if (!state.dropOffDate || new Date(state.dropOffDate) <= new Date()) {
                state.carHireValidationErrors['dropOffDate'] = 'No end date'
            }

            if (state.dropOffDate && state.pickUpDate && state.pickUpDate === state.dropOffDate) {
                if (parseInt(state.pickUpTime) >= parseInt(state.dropOffTime)) {
                    state.carHireValidationErrors['dropOffTime'] = 'Select a later time'
                }
            }
        },
        setReturnToSameLocation: (state, payload) => (state.returnToSameLocation = payload),
        setPickUpLocation: (state, payload) => (state.pickUpLocation = payload),
        setPickUpDate: (state, payload) => (state.pickUpDate = payload),
        setPickUpTime: (state, payload) => (state.pickUpTime = payload),
        setDropOffLocation: (state, payload) => (state.dropOffLocation = payload),
        setDropOffDate: (state, payload) => (state.dropOffDate = payload),
        setDropOffTime: (state, payload) => (state.dropOffTime = payload),
        setDriverAge: (state, payload) => (state.driverAge = payload),
        loadOntoCarHireSessionStorage(state) {
            const sessionStorageProxy = storageProxy(window.sessionStorage)
            sessionStorageProxy.setItem('carhire-enquiry-session', JSON.stringify(gadgetDataTransformer.transform(state)))
        },
        generateCarHireResultsUrl(state) {
            const gadgetData = gadgetDataTransformer.transform(state)
            state.carHireResultsUrl = resultsUrlGenerator.generate(gadgetData)
        },
        addCarHireValidationError(state, payload) {
            state.carHireValidationErrors = Object.assign(payload, state.carHireValidationErrors)
        }
    },
    actions: {
        validateCarHireGadget: ({ commit }) => commit('validateCarHireGadget'),
        setReturnToSameLocation: ({ commit }, payload) => commit('setReturnToSameLocation', payload),
        setPickUpLocation: ({ commit }, payload) => commit('setPickUpLocation', payload),
        setPickUpDate: ({ commit }, payload) => commit('setPickUpDate', payload),
        setPickUpTime: ({ commit }, payload) => commit('setPickUpTime', payload),
        setDropOffLocation: ({ commit }, payload) => commit('setDropOffLocation', payload),
        setDropOffDate: ({ commit }, payload) => commit('setDropOffDate', payload),
        setDropOffTime: ({ commit }, payload) => commit('setDropOffTime', payload),
        setDriverAge: ({ commit }, payload) => { commit('setDriverAge', payload) },
        submitCarHireGadget({ commit, getters }) {
            commit('loadOntoCarHireSessionStorage')
            commit('generateCarHireResultsUrl')
            if (getters.carHireResultsUrl) {
                //HOT-1003 AB Test
                const abService = ABService.build()
                const isInTest = abService.isInTest() && abService.getTestName().startsWith('HOT-1003')
                    && ("B" === abService.getVariantName())
                // HOT-1003: Leave behind gadget logic - Needs productionising if AB test is successful
                if (isInTest) {
                    const leaveBehindMap = { "B": "/en-gb/car-hire/excess-insurance/dont-forget/" }
                    const variant = abService.getVariantName()
                    const leaveBehindUrl = leaveBehindMap[variant]
                    setTimeout(function () {
                        window.location = leaveBehindUrl
                    }, 1000)
                    window.open(getters.carHireResultsUrl, '_blank')
                } else {
                    // Current behaviour outside of the AB test
                    window.location = getters.carHireResultsUrl;
                } 
            } else {
                commit('addCarHireValidationError', { gadget: 'Unable to perform a search at the moment. Please try again later.' })
            }
        }
    },
    getters: {
        carHireValidationErrors: (state) => state.carHireValidationErrors,
        returnToSameLocation: (state) => state.returnToSameLocation,
        pickUpLocation: (state) => state.pickUpLocation,
        pickUpDate: (state) => state.pickUpDate,
        pickUpTime: (state) => state.pickUpTime,
        dropOffLocation: (state) => state.dropOffLocation,
        dropOffDate: (state) => state.dropOffDate,
        dropOffTime: (state) => state.dropOffTime,
        driverAge: (state) => state.driverAge,
        carHireResultsUrl: (state) => state.carHireResultsUrl
    }
}