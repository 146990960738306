import gadgetDataTransformer from './GadgetDataTransformer'
import resultsUrlGenerator from './ResultsUrlGenerator'
import destinationFinderUrlGenerator from "./DestinationFinderUrlGenerator";
import sessionStorageTransformer from '../../../SessionStorageTransformer'
import { storageProxy } from '../../../StorageProxy'
import HelperFunctions from '../../../HelperFunctions'
import ABService from "../../../ABService"

export default {
    state: {
        departures: [], //TODO: WHEN THE NEW GADGET IS 100% - CHANGE THIS TO BE NULL BY DEFAULT BECAUSE ITS NOW AN AUTOCOMPLETE
        destination: null,
        departureDate: '',
        returnDate: '',
        nights: 0,
        adults: 0,
        numChildren: 0,
        children: [],
        boardBasis: [],
        starRating: [],
        validationErrors: {},
        resultsUrl: null
    },
    mutations: {
        //TODO: This will need to be removed once we are only using the new gadget
        validateGadget(state) {
            //Reset previous validation errors
            state.validationErrors = {}

            //validate departures
            const departures = state.departures.map(departure => departure.value)
            const validDepartures = departures.filter(departure => (departure !== ''))
            if (validDepartures.length < 1) {
                state.validationErrors['departures'] = 'Please enter a departure location'
            }
            //validate destination
            if (!state.destination || !state.destination.id || Object.keys(state.destination).length === 0) {
                state.validationErrors['destination'] = 'Please enter a destination location'
            }
            //validate datepicker departureDate
            if (!state.departureDate || new Date(state.departureDate) <= new Date()) {
                state.validationErrors['departureDate'] = 'Please enter a departure date'
            }
            //validate children ages
            const childrenAges = state.children.map(childAge => childAge.value)
            if (childrenAges.length != state.numChildren || childrenAges.includes('?')) {
                state.validationErrors['children'] = 'Please select child ages'
            }
            //validate total number of travelers
            if ((state.adults + state.numChildren) > 9) {
                state.validationErrors['gadget'] = 'Please reduce total number of travellers to 9'
            }
        },
        validateGadgetV2(state) {
            //Reset previous validation errors
            state.validationErrors = {}

            //validate departures
            const departures = state.departures.map(departure => departure.value)
            const validDepartures = departures.filter(departure => (departure !== ''))
            if (validDepartures.length < 1) {
                state.validationErrors['departures'] = 'Select'
            }
            //validate destination
            if (!state.destination || !state.destination.id || Object.keys(state.destination).length === 0) {
                state.validationErrors['destination'] = 'Select'
            }
            //validate datepicker departureDate
            if (!state.departureDate || new Date(state.departureDate) <= new Date()) {
                state.validationErrors['departureDate'] = 'Select'
            }
            //validate datepicker returnDate
            if (!state.returnDate || new Date(state.returnDate) <= new Date()) {
                state.validationErrors['returnDate'] = 'Select'
            }
            //validate children ages
            const childrenAges = state.children.map(childAge => childAge.value)
            if (childrenAges.length != state.numChildren || childrenAges.includes('?')) {
                state.validationErrors['children'] = 'Select child age'
            }
            //validate total number of travelers
            if ((state.adults + state.numChildren) > 9) {
                state.validationErrors['gadget'] = 'Please reduce total number of travellers to 9'
            }
        },
        addValidationError(state, payload) {
            state.validationErrors = Object.assign(payload, state.validationErrors)
        },
        generateResultsUrl(state) {
            const gadgetData = gadgetDataTransformer.transform(state)
            if (gadgetData.destination === "any") {
              state.resultsUrl = destinationFinderUrlGenerator.generate(gadgetData)
            } else {
              state.resultsUrl = resultsUrlGenerator.generate(gadgetData);
            }
        },
        loadOntoSessionStorage(state, payload) {
            const sessionStorageProxy = storageProxy(window.sessionStorage)
            sessionStorageProxy.setItem(`${payload.channel}-enquiry-session`, JSON.stringify(sessionStorageTransformer.transformToSessionFormat(state, payload.channel)))
        },
        addDeparture(state, payload) {
            //If elementId exists, replace
            const elementIdIdx = state.departures.findIndex(item => item.elementId === payload.elementId)
            if (elementIdIdx !== -1) {
                state.departures.splice(elementIdIdx, 1, payload)
            } else {
                state.departures.push(payload)
            }
        },
        removeDeparture(state, payload) {
            state.departures = state.departures.filter(departure => (departure.elementId !== payload))
        },
        addChildrenWithAges(state, payload) {
            const idx = state.children.findIndex(item => item.id === payload.id);
            if (idx !== -1) {
                state.children.splice(idx, 1, payload);
                state.numChildren = state.children.length
            } else {
                state.children.push(payload);
            }
        },
        removeChild(state) {
            state.children.pop()
            state.numChildren = state.children.length
        },
        spliceChildrenAges: (state, payload) => {
            state.numChildren = payload
            state.children.splice(payload, state.children.length)
        },
        setNumChildren: (state, payload) => { state.numChildren = payload },
        addNights: (state, payload) => state.nights = payload,
        setNights: (state) => {
            if (state.returnDate) {
                state.nights = HelperFunctions.getNumberOfDays(state.departureDate, state.returnDate)
            }
        },
        addAdults: (state, payload) => state.adults = payload,
        setDepartureDate: (state, payload) => (state.departureDate = payload),
        setReturnDate: (state, payload) => (state.returnDate = payload),
        setDestination: (state, payload) => (state.destination = payload),
        addBoardBasis(state, payload) {
            let alreadyPresent = false

            for (let boardBasis of state.boardBasis) {
                if (boardBasis === payload) {
                    alreadyPresent = true
                    break
                }
            }
            if (!alreadyPresent) {
                state.boardBasis.push(payload)
            }
        },
        removeBoardBasis(state, payload) {
            state.boardBasis = state.boardBasis.filter(boardBasis => (boardBasis !== payload))
        },
        addStarRating(state, payload) {
            let alreadyPresent = false

            for (let starRating of state.starRating) {
                if (starRating === payload) {
                    alreadyPresent = true
                    break
                }
            }
            if (!alreadyPresent) {
                state.starRating.push(payload)
            }
        },
        removeStarRating(state, payload) {
            state.starRating = state.starRating.filter(starRating => (starRating !== payload))
        }
    },
    actions: {
        addDeparture: ({ commit }, payload) => commit('addDeparture', payload),
        removeDeparture: ({ commit }, payload) => commit('removeDeparture', payload),
        addChildrenWithAges: ({ commit }, payload) => commit('addChildrenWithAges', payload),
        removeChild: ({ commit }) => commit('removeChild'),
        spliceChildrenAges: ({ commit }, payload) => commit('spliceChildrenAges', payload),
        setNumChildren: ({ commit }, payload) => commit('setNumChildren', payload),
        addNights: ({ commit }, payload) => commit('addNights', payload),
        setNights: ({ commit }) => commit('setNights'),
        addAdults: ({ commit }, payload) => commit('addAdults', payload),
        setDepartureDate: ({ commit }, payload) => commit('setDepartureDate', payload),
        setReturnDate: ({ commit }, payload) => commit('setReturnDate', payload),
        setDestination: ({ commit }, payload) => commit('setDestination', payload),
        addBoardBasis: ({ commit }, payload) => commit('addBoardBasis', payload),
        removeBoardBasis: ({ commit }, payload) => commit('removeBoardBasis', payload),
        addStarRating: ({ commit }, payload) => commit('addStarRating', payload),
        removeStarRating: ({ commit }, payload) => commit('removeStarRating', payload),
        validateGadget: ({ commit }) => commit('validateGadget'), //TODO: This will need to be removed once we are only using the new gadget
        validateGadgetV2: ({ commit }) => commit('validateGadgetV2'),
        async loadClickTripzSettingsAndRedirect ({ dispatch }, params) {
            const settings = await dispatch("loadClickTripzAdSettings", params);
            if (settings) {
              await dispatch("redirectWithBackTab", params);
            } else {
              console.error("Failed to load ClickTripz settings");
            }
        },
        async submitGadget({ commit, getters, rootState, dispatch }) {
            commit('loadOntoSessionStorage', { channel: rootState.components.channel })
            commit('generateResultsUrl')
            if (getters.resultsUrl) {
                const abService = ABService.build()
                const isInTest = abService.isInTest() && abService.getTestName().startsWith('TRAVL-15720')
                    && ("B" === abService.getVariantName() || "C" === abService.getVariantName())
                // TRAVL-16757: Leave behind gadget logic - Needs productionising if AB test is successful.
                if (isInTest) {
                    const leaveBehindMap= { "B": "/en-gb/extras/thankyou/", "C": "/en-gb/holidays/travel-advice/thankyou/" }
                    const variant = abService.getVariantName()
                    const leaveBehindUrl = leaveBehindMap[variant]
                    setTimeout(function () {
                        window.location = leaveBehindUrl
                    }, 1000)
                    window.open(getters.resultsUrl, '_blank')
                } else {
                    // Current behaviour outside of the AB test
                    if (rootState.layout && rootState.layout.layout) {
                        const params = {
                            layout: rootState.layout.layout,
                            resultsUrl: getters.resultsUrl,
                            searchState: rootState.holidaysGadget,
                        };
                        await dispatch("loadClickTripzSettingsAndRedirect", params);
                      } else {
                        window.location = getters.resultsUrl;
                      }
                }
            } else {
                commit('addValidationError', { gadget: 'Unable to perform a search at the moment. Please try again later.' })
            }
        }
    },
    getters: {
        departures: (state) => state.departures,
        destination: (state) => state.destination,
        departureDate: (state) => state.departureDate,
        returnDate: state => state.returnDate,
        starRating: (state) => state.starRating,
        boardBasis: (state) => state.boardBasis,
        adults: (state) => state.adults,
        nights: (state) => state.nights,
        numChildren: (state) => state.numChildren,
        children: (state) => state.children,
        validationErrors: (state) => state.validationErrors,
        gadgetIsValid: (state) => Object.keys(state.validationErrors).length === 0,
        resultsUrl: (state) => state.resultsUrl
    }
}
