'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import breadcrumbs from './modules/breadcrumbs'
import carHireDeals from './modules/carHireDeals'
import clickTripz from './modules/adverts/clickTripz'
import components from './modules/components'
import signup from './modules/signup'
import holidaysGadget from './modules/holidaysGadget'
import carHireGadget from './modules/carHireGadget'
import adverts from './modules/adverts'
import layout from './modules/layout'
import exposedLinks from './modules/exposedLinks'
import exposedResults from './modules/exposedResults'
import priceLinkBlock from './modules/priceLinkBlock'
import text from './modules/text'
import listings from './modules/listings'
import modal from './modules/modal'
import apiHost from './modules/apiHost'

Vue.use(Vuex)

export const modules = {
    breadcrumbs,
    carHireDeals,
    clickTripz,
    components,
    signup,
    carHireGadget,
    holidaysGadget,
    adverts,
    layout,
    exposedLinks,
    exposedResults,
    priceLinkBlock,
    text,
    listings,
    modal,
    apiHost
}

export default () => {
    return new Vuex.Store({
        modules: modules
    })
}