'use strict'

export default {
    state: {
        data: {},
        name: ''
    },
    mutations: {
        setData: (state, payload) => state.data = payload ? payload : {},
        setName: (state, payload) => state.name = payload ? payload : ''
    },
    actions: {
        openModal: ({ commit }, { name: name, data: data }) => {
            commit('setData', data)
            commit('setName', name)
        },
        clearModal: ({ commit }) => {
            commit('setData')
            commit('setName')
        }
    },
    getters: {
        isModalOpen: (state) => state.name !== '',
        modalData: (state) => state.data,
        modalName: (state) => state.name
    }
}