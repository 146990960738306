'use strict'
let axios
import Vue from 'vue'
import enquiryUrlGenerator from './EnquiryUrlGenerator'
import HelperFunctions from '@/HelperFunctions'

export default {
  state: {
    carHireDeals: {},
    carHireTabbed: {},
    carHireCheapestPrice: null,
    carHireDealsHost: null
  },
  getters: {
    carHireDeals: state => id => state.carHireDeals[id],
    carHireTabbed: state => name => state.carHireTabbed[name],
    carHireCheapestPrice: (state) => state.carHireCheapestPrice,
    carHireDealsHost: (state) => state.carHireDealsHost
  },
  mutations: {
    carHireDeals: (state, payload) => Vue.set(state.carHireDeals, payload.id, payload.data),
    setCarHireCheapestPrice: (state, payload) => state.carHireCheapestPrice = payload.price,
    carHireTabbed: (state, payload) => Vue.set(state.carHireTabbed, payload.name, payload.active),
    carHireDealsHost: (state, payload) => Vue.set(state, 'carHireDealsHost', payload)
  },
  actions: {
    async fetchCarHireDeals({ commit, state, rootState, dispatch }, payload) {
      axios = await import(/* webpackChunkName: "tsm-axios" */ "axios")
      const dealsApiHost = ('prod' === state.carHireDealsHost) ? rootState.apiHost.apiUrlProd : rootState.apiHost.apiUrlTest
      const url = `${dealsApiHost}/global/carhire-deals/v2/locations/${payload.id}`
      return await axios.get(url)
        .then(response => {
          if (response && response.data && response.data.deals && response.data.deals.length > 0) {
            const obj = {
              generationDate: response.data.generationDate,
              deals: response.data.deals
            }
            commit('carHireDeals', { id: payload.id, data: obj })
            if (response.data.cheapestDeal) {
              dispatch('setCarHireCheapestPrice', { price: response.data.cheapestDeal })
            }
            if (payload.tabbedDeal) {
              commit('carHireTabbed', { name: HelperFunctions.removeSpaces(payload.tabbedTitle).toLowerCase(), active: true })
          }
          }
        }).catch((error) => {
          dispatch('createSearchEnquiry', { id: payload.id })
          console.error(`Error fetching Exposed Results from ${url}: ${error}`);
        })
    },
    async createSearchEnquiry(_, payload) {
      await axios.get(enquiryUrlGenerator.generate(payload))
    },
    setCarHireCheapestPrice: ({ commit, state }, payload) => {
      if (state.carHireCheapestPrice === null || payload.price < state.carHireCheapestPrice) {
        commit('setCarHireCheapestPrice', { price: payload.price })
      }
    }
  }
}