export const LazyLoad = {
    inserted: (el, binding) => {
        const intersection = new Intersection(el, binding)
        !window['IntersectionObserver'] ? intersection.loadImage() : intersection.createObserver()
    }
}

export class Intersection {
    constructor(el, binding) {
        this.el = el
        this.binding = binding
    }
    loadImage() {
        this.el.addEventListener('load', () => setTimeout(() => this.el.classList.add('loaded'), 100))
        if (this.binding.arg === 'bg') {
            this.el.style.backgroundImage = `url(${this.el.dataset.url})`
        } else if (this.binding.arg === 'img') {
            this.el.src = this.el.dataset.url
        }
    }
    createObserver() {
        const options = { root: null, threshold: '0' }
        const self = this
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    self.loadImage()
                    observer.unobserve(this.el)
                }
            })
        }, options)
        observer.observe(this.el)
    }
}