"use strict";
export default {
  state: {
    apiUrlProd: 'https://api.travelsupermarket.com',
    apiUrlTest: 'https://staging-api.travelsupermarket.com',
  },
  getters: {
    apiUrlProd: (state) => state.apiUrlProd,
    apiUrlTest: (state) => state.apiUrlTest,
  },
};
