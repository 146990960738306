<template>
  <div id="app" @scroll.passive="onScroll">
    <svg-defs />
    <component
      v-for="(com, idx) in components"
      :key="`sonic-components-${idx}`"
      :is="Object.keys(com)[0]"
      :data="com[Object.keys(com)[0]]"
    ></component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import components from "@/components.js";

export default {
  components: components,
  computed: {
    ...mapGetters(["components","cheapestPrice","carHireCheapestPrice"]),
  },
  beforeMount() {
    return true;
  },
  serverPrefetch() {
    return this.$store.dispatch("cleanChannelName");
  },
  metaInfo() {
    this.currentTitle = (typeof window !== 'undefined' && document.title) ? document.title : ''
    if(this.cheapestPrice || this.carHireCheapestPrice) {
      let titleAppend = this.carHireCheapestPrice ? ` from £${this.carHireCheapestPrice.toFixed(2)} per day` : ` from £${this.cheapestPrice}pp`    
      this.currentTitle = this.currentTitle.replace(/(\s+)?from £\d+(\.\d{1,2})?(pp| per day)/g,'')
      return {
        title: `${this.currentTitle}${titleAppend}`
      }
    }
  }  
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

::placeholder {
  opacity: 1;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: 2px solid theme("colors.purple.dark");
  outline-offset: 2px;
}

.checkbox-label-focus:focus + label {
  outline: 2px solid theme("colors.white");
  outline-offset: 2px;
}

.checkbox-label-focus--dark:focus + label {
  outline: 2px solid theme("colors.purple.dark");
  outline-offset: 2px;
}

iframe {
  max-width: 100%; /* will stop adverts breaking the layout */
}

.ie10-flex-override {
  flex: 1 0 auto; /* because ie10 doesn't like flex: 1 or other flex bits. */
}

.sg__filters {
  @screen lg {
    width: 43%;
    top: 59px;
    left: 25%;
    margin-left: 16px;
  }
}

.sg__global-error .sg__filters {
  @screen lg {
    top: 76px;
  }
}

.sg__error {
  box-shadow: 0 0 1px 2px theme("colors.signal-error");
}

//TODO: This will need to be refactored once the new gadget is live
.sg__error-v2 {
  outline: 2px solid theme("colors.signal-error");
  outline-offset: -4px;
}

select::-ms-value,
select:focus::-ms-value,
.flatpickr-monthDropdown-months:focus::-ms-value {
  @apply text-black bg-transparent;
}

select::-ms-expand {
  @apply hidden;
}

pre {
  @apply max-w-full overflow-auto;
}

.cms-content a {
  /* TODO 'text-text-link' tailwind classes need a better name */
  @apply border-b-2 text-text-link border-text-link no-underline font-semibold;
}
.cms-content a:hover,
.cms-content a:focus {
  @apply border-dashed text-text-link-dark border-text-link-dark;
}

.cms-content a:focus {
  @apply rounded
}

.cms-content p {
  @apply mb-3 text-base;
}

.cms-content h1 {
  @apply text-xl;
  @screen md {
    @apply text-3xl;
  }
}

.cms-content h2 {
  @apply text-xl font-bold mb-3;
}

.cms-content h3 {
  @apply text-lg font-bold mb-3;
}

.cms-content h4 {
  @apply text-base font-bold mb-3;
}

.cms-content img {
  @apply mb-3;
}

.cms-content figure img,
figure.cms-content img {
  @apply mb-1;
}

.cms-content p img {
  @apply mb-0;
}

.cms-content ul,
.cms-content ol,
.cms-content li {
  @apply ml-3 mb-3;
}

.cms-content ul {
  list-style-type: disc;
}

.cms-content ol {
  list-style-type: decimal;
}

.cms-content table {
  @apply w-full;
}

.cms-content th,
.cms-content td {
  @apply border-1 border-grey-light p-2;
}

.cms-content tr:nth-child(odd) {
  @apply bg-grey-light;
}

.cms-content .mobilestacked,
.cms-content .mobilestacked tr,
.cms-content .mobilestacked th,
.cms-content .mobilestacked td {
  @apply block;
}

.cms-content .mobilestacked tr {
  @apply my-4;
}
	
.cms-content .mobilestacked tr:first-child {
  @apply hidden;
}

.cms-content .mobilestacked td {
  @apply w-auto overflow-hidden break-words;
  text-align: left !important;
  max-width: calc(100vw - 64px);
}	

.cms-content .mobilestacked td:first-child {
  @apply font-bold;
}

@screen md {
  .cms-content .mobilestacked {
    @apply block;
  }
  .cms-content .mobilestacked tr,
  .cms-content .mobilestacked tr:first-child {
    @apply m-0 table-row;
  }      
  .cms-content .mobilestacked th,
  .cms-content .mobilestacked td {
    @apply table-cell;
  }      

  .cms-content .mobilestacked td:first-child {
    @apply font-normal
  }
}

.tsm-text-link {
  /* TODO 'text-text-link' tailwind classes need a better name */
  @apply text-text-link border-text-link underline;
}

.tsm-text-link:hover,
.tsm-text-link:focus {
  @apply text-text-link-dark no-underline;
}

.tsm-text-link:focus {
  outline: none;
  @apply rounded-1 #{!important};
  -webkit-box-shadow: 0 0 0 3px white, 0 0 0 5px theme("colors.purple.dark");
  box-shadow: 0 0 0 2px theme("colors.purple.dark");
}

.tsm-text-link-plain {
  /* TODO 'text-text-link' tailwind classes need a better name */
  @apply text-text-link border-text-link;
}
.tsm-text-link-plain:hover,
.tsm-text-link-plain:focus {
  @apply text-text-link-dark underline;
  text-decoration-style: dashed;
}

.tsm-text-link-plain:focus {
  outline: none;
  @apply rounded-1 #{!important};
  -webkit-box-shadow: 0 0 0 3px white, 0 0 0 5px theme("colors.purple.dark");
  box-shadow: 0 0 0 2px theme("colors.purple.dark");
}
</style>
