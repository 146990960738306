'use strict'

import Vue from 'vue'

export default {
    state: {
        exposedLinks: {},
        testUrl: 'https://www.pre1.gb.travelsupermarket.com',
        prodUrl: 'https://www.travelsupermarket.com',
        externalResultsHost: null
    },
    getters: {
        exposedLinks: state => state.exposedLinks,
        externalResultsHost: state => state.externalResultsHost
    },
    mutations: {
        setExposedLinks: (state, payload) => Vue.set(state, 'exposedLinks', payload),
        externalResultsHost: (state, payload) => Vue.set(state, 'externalResultsHost', payload)
    },
    actions: {
        async checkExposedLinks({ commit, state }, payload) {
            const promises = []
            const host = ('prod' === state.externalResultsHost) ? state.prodUrl : state.testUrl
            let exposedResults = { title: payload.title, links: [] }
            const axios = await import( /* webpackChunkName: "tsm-axios" */ 'axios');
            payload.links.forEach(link => {
                promises.push(axios.get(`${host}${link.link}`)
                    .then(resp => {
                        if (resp.status === 200) {
                            exposedResults.links.push(link)
                        }
                    })
                    .catch(() => {/**Do nothing, just catch the error. */ }))
            })
            return Promise.all(promises).then(() => commit('setExposedLinks', exposedResults))
        }
    }
}