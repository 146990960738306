'use strict'

import Vue from 'vue'

export default {
    state: {
        destinationUrl: null
    },
    getters: {
        destinationUrl: (state) => state.destinationUrl
    },
    mutations: {
        destinationUrl: (state, payload) => Vue.set(state, 'destinationUrl', payload)
    },
    actions: {
    }
}
