'use strict'
require('es6-promise').polyfill()
import Vue from 'vue'
import "./assets/tailwind.css"
import './assets/flatpickr.css'
import { LazyLoad } from "@/directives/LazyLoad.js"
import VueFlatPickr from 'vue-flatpickr-component'
import '@/plugins/focus-visible/index.js'
import componentLoader from '@/plugins/component-lazy-loader';
import VueMeta from 'vue-meta'
Vue.directive("lazy-load", LazyLoad);
Vue.use(VueFlatPickr);
Vue.use(componentLoader);
Vue.use(VueMeta)
import App from './App.vue'
import Store from './store'
Vue.config.productionTip = false
const store = Store()
const app = new Vue({ store, render: h => h(App) })
export { app, store }
