'use strict'

import Vue from 'vue'

export default {
    state: {
        text: {},
        leadIn: {}
    },
    getters: {
        getTextContent: (state, rootState) => id => rootState.layout === 'mobile' || rootState.layout === 'tablet' ? state.text[id] ? state.text[id].mobile : null : state.text[id] ? state.text[id].desktop : null,
        leadInText: (state) => id => state.leadIn[id] || ''
    },
    mutations: {
        setTextContent: (state, payload) => Vue.set(state.text, payload.id, payload.content),
        setLeadIn: (state, payload) => Vue.set(state.leadIn, payload.id, payload.leadIn)
    }
}