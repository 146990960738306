'use strict'

export default {
    MONTHS: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
    TIMES: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
    ],
    DRIVER_AGES: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75],
    BOARD_BASIS_MAP: [
        {
            label: "All Inclusive",
            value: "AI"
        },
        {
            label: "Full Board",
            value: "FB"
        },
        {
            label: "Half Board",
            value: "HB"
        },
        {
            label: "Breakfast Included",
            value: "BB"
        },
        {
            label: "Self Catering",
            value: "SC"
        },
        {
            label: "Room Only",
            value: "RO"
        },
    ],
}