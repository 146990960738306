class GadgetDataTransformer {

    transform(gadgetData) {
        let obj = {
            pickUpLocation: {
                id: gadgetData.pickUpLocation.id,
                name: gadgetData.pickUpLocation.name,
                isGroup: false,
                selectable: true,
            },
            sameLocation: !this._differentPickUpDropOff(gadgetData),
            pickUpDateTimeString: `${gadgetData.pickUpDate}T${gadgetData.pickUpTime}`,
            dropOffDateTimeString: `${gadgetData.dropOffDate}T${gadgetData.dropOffTime}`,
            driverAge: gadgetData.driverAge,
            isDriverAgeChecked: gadgetData.driverAge === 33
        };

        if (this._differentPickUpDropOff(gadgetData)) {
            obj.dropOffLocation = {
                id: gadgetData.dropOffLocation.id,
                name: gadgetData.dropOffLocation.name,
                isGroup: false,
                selectable: true,
            };
        }

        return this._cleanObject(obj);
    }

    _differentPickUpDropOff(gadgetData) {
        return (
            !gadgetData.returnToSameLocation &&
            gadgetData.dropOffLocation &&
            gadgetData.dropOffLocation.id !== gadgetData.pickUpLocation.id
        )
    }

    _cleanObject(unclean) {
        let cleaned = {}
        for (let [key, value] of Object.entries(unclean)) {
            if (value === false || value) {
                cleaned[key] = value
            }
        }
        return cleaned
    }

}

const gadgetDataTransformer = new GadgetDataTransformer()
export default gadgetDataTransformer
