'use strict'

import Vue from 'vue'
import HelperFunctions from '@/HelperFunctions'

export default {
    state: {
        exposedResults: {},
        exposedResultsTabbed: {},
        numberOfExposedResults: 0,
        exposedResultsRetrieved: 0,
        exposedResultsHost: null,
        cheapestPrice: null,
        exposedResultsMode: 'prod',
        testApiHost: 'https://gcp-holidays-exposed-results-api-v1-ng4zhtbzua-ew.a.run.app',
        prodApiHost: 'https://gcp-holidays-exposed-results-api-v1-pqpa4n2czq-ew.a.run.app',
        testImageHost: 'https://img.staging.icetravelgroup.com',
        prodImageHost: 'https://img.icetravelgroup.com',
    },
    getters: {
        exposedResultsMode: state => state.exposedResultsMode,
        exposedResults: state => name => state.exposedResults[name],
        exposedResultsTabbed: state => name => state.exposedResultsTabbed[name],
        exposedResultsHost: (state) => state.exposedResultsHost,
        exposedResultsGenerationDate: (state) => state.exposedResultsGenerationDate,
        cheapestPrice: (state) => state.cheapestPrice,
    },
    mutations: {
        numberOfExposedResults: (state, payload) => Vue.set(state, 'numberOfExposedResults', payload),
        exposedResultsMode: (state, payload) => Vue.set(state, 'exposedResultsMode', payload),
        exposedResults: (state, payload) => Vue.set(state.exposedResults, payload.name, payload.exposedResults),
        exposedResultsHost: (state, payload) => Vue.set(state, 'exposedResultsHost', payload),
        exposedResultsTabbed: (state, payload) => Vue.set(state.exposedResultsTabbed, payload.name, payload.active),
    },
    actions: {
        async fetchExposedResults({ commit, state, dispatch }, payload) {
            const axios = await import(/* webpackChunkName: "tsm-axios" */ "axios")
            const apiHost = ('prod' === state.exposedResultsHost) ? state.prodApiHost : state.testApiHost
            const imageHost = ('prod' === state.exposedResultsHost) ? state.prodImageHost : state.testImageHost

            const url = `${apiHost}/holidays-exposed-results-api/v1/results?key=${payload.key}&limit=8`
            return await axios.get(url)
                .then(response => {
                    state.exposedResultsRetrieved++
                    if (response && response.data && response.data.results && response.data.results.length > 0) {
                        const results = response.data.results
                        results.forEach(result => {
                            const separator = result.imageUrl.startsWith('/') ? '' : '/'
                            result.imageUrl = `${imageHost}${separator}${result.imageUrl}`
                        });

                        const exposedResults = {
                            accommodations: results,
                            exposedResultsGenerationDate: response.data.meta.updatedDateTimeMin
                        }
                        commit('exposedResults', { name: payload.key, exposedResults: exposedResults })
                        dispatch('setCheapestExposedResultsPrice', { exposedResults: exposedResults })
                        if (payload.tabbedDeal) {
                            commit('exposedResultsTabbed', { name: HelperFunctions.removeSpaces(payload.tabbedTitle).toLowerCase(), active: true })
                        }
                    }
                }).catch((error) => {
                    console.error(`Error fetching Exposed Results from ${url}: ${error}`);
                    state.exposedResultsRetrieved++
                })
        },
        setCheapestExposedResultsPrice: ({ state }, payload) => {
            payload.exposedResults.accommodations.forEach(result => {
                if (state.cheapestPrice === null || result.price < state.cheapestPrice) {
                    Vue.set(state, 'cheapestPrice', result.price)
                }
            });
        }
    }
}