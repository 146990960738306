'use strict'
import Vue from 'vue'
import { app, store } from './app'
import './registerServiceWorker'
store.replaceState(window.__PAGE_STATE__);
store.commit('setLayout', window.innerWidth || document.documentElement.clientWidth)
store.commit('replaceTitle')
import(/* webpackChunkName: "atf-ga" */ './plugins/ga/index.js').then(module => Vue.use(module.default(store.getters.channel, store.getters.page)))
import(/* webpackChunkName: "atf-ga4" */ './plugins/ga4/index.js').then(module => Vue.use(module.default(store.getters.channel)))
app.$mount('#app');
