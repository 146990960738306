'use strict'

import HelperFunctions from "./HelperFunctions"
const TEST_PREFIX = 'AB-'
export default class ABService {

    constructor(helperFunction) {
        
        this.testCookie = helperFunction.readCookiesWithPrefix(TEST_PREFIX)
        try {
            this.testCookie = this.testCookie.map(cookie => { return { name: cookie.name, value: JSON.parse(cookie.value) } })[0]
        }
        catch {
            console.log('err')
        }
    }

    isInTest() {
        return this.testCookie !== undefined && this.testCookie !== null
    }

    getTestName() {
        return this.testCookie.name.replace(TEST_PREFIX, '')
    }

    getVariantName() {
        return this.testCookie.value.variant
    }

    static build() {
        return new ABService(HelperFunctions)
    }
}
