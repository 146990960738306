class SessionStorageTransformer {
  
  transformFromSessionFormat(sessionData) {
    let departureLocations = []
    sessionData.departureLocations.forEach(location => {
      departureLocations.push(location.id)
    })

    let childAges = []
    sessionData.rooms[0].childAges.forEach(child => {
      childAges.push(child.age)
    })

    return {
      destination: sessionData.destination,
      departures: departureLocations,
      departureDate: sessionData.departureDateString,
      nights: sessionData.duration,
      adults: sessionData.rooms[0].adults,
      numChildren: sessionData.rooms[0].childAges.length,
      children: childAges,
      boardBasis: sessionData.boardBasis,
      starRating: sessionData.stars.map(s => parseInt(s))
    }
  }

  transformToSessionFormat(gadgetData, channel) {
    let departureLocations = []
    gadgetData.departures.forEach(location => {
      departureLocations.push({ id: location.value })
    })

    let children = []
    gadgetData.children.forEach(child => {
      children.push({ age: child.value })
    })

    return {
      departureLocations: departureLocations,
      destination: gadgetData.destination,
      departureDateString: gadgetData.departureDate,
      duration: gadgetData.nights,
      rooms: [
        {
          adults: gadgetData.adults,
          childAges: children
        }
      ],
      channel: channel,
      boardBasis: gadgetData.boardBasis,
      stars: gadgetData.starRating.map(s => s.toString()),
    }
  }
}

const sessionStorageTransformer = new SessionStorageTransformer()
export default sessionStorageTransformer