'use strict'

import Vue from 'vue'

export default {
    state: {
        mobile: { min: 0, max: 767 },
        tablet: { min: 768, max: 989 },
        desktop: { min: 990, max: 1299 },
        desktopXl: { min: 1300 },
        layout: null,
        viewportWidth: null
    },
    getters: {
        layout: (state) => state.layout,
        viewportWidth: state => state.viewportWidth,
    },
    mutations: {
        viewportWidth: (state, payload) => Vue.set(state, 'viewportWidth', payload),
        setLayout: (state, payload) => {
            Vue.set(state, 'viewportWidth', payload)
            if (payload <= state.mobile.max) {
                Vue.set(state, 'layout', 'mobile')
            }
            else if (payload >= state.tablet.min && payload <= state.tablet.max) {
                Vue.set(state, 'layout', 'tablet')
            }
            else if (payload >= state.desktop.min && payload <= state.desktop.max) {
                Vue.set(state, 'layout', 'desktop')
            }
            else if (payload >= state.desktopXl.min) {
                Vue.set(state, 'layout', 'desktop-xl')
            }
        }
    },
    actions: {
        setLayout: ({ commit }, payload) => commit('setLayout', payload)
    }
}
