'use strict'

import Vue from 'vue'
import HelperFunctions from '@/HelperFunctions'

export default {
    state: {
        priceLinkBlock: {},
        testCheckUrl: 'https://www.pre1.gb.travelsupermarket.com',
        prodCheckUrl: 'https://www.travelsupermarket.com',
        testApiUrl: 'https://gcp-holidays-exposed-results-api-v1-ng4zhtbzua-ew.a.run.app',
        prodApiUrl: 'https://gcp-holidays-exposed-results-api-v1-pqpa4n2czq-ew.a.run.app',
        priceLinkBlockExternalResultsHost: null
    },
    getters: {
        priceLinkBlock: state => state.priceLinkBlock,
        priceLinkBlockExternalResultsHost: state => state.priceLinkBlockExternalResultsHost
    },
    mutations: {
        setPriceLinkBlock: (state, payload) => Vue.set(state.priceLinkBlock, payload.id , payload.exposedResults),
        priceLinkBlockExternalResultsHost: (state, payload) => Vue.set(state, 'priceLinkBlockExternalResultsHost', payload)
    },
    actions: {
        async processPriceLinkBlock({ commit, state, dispatch }, payload) {
            const promises = []

            const checkHost = (state.priceLinkBlockExternalResultsHost === 'prod') ? state.prodCheckUrl : state.testCheckUrl
            const apiHost = (state.priceLinkBlockExternalResultsHost === 'prod') ? state.prodApiUrl : state.testApiUrl
            const axios = await import( /* webpackChunkName: "tsm-axios" */ 'axios');

            let exposedResults = { title: payload.title, items: [] }
            let exposedResultsKeys = []
            let prices = []

            payload.items.forEach(item => {
                exposedResultsKeys.push(HelperFunctions.cleanUrl(item.exposedResultsKey))
            })

            const url = `${apiHost}/holidays-exposed-results-api/v1/prices?keys=${exposedResultsKeys.toString()}`
            await axios.get(url)
                .then(response => {
                    if (response && response.data && response.data.prices && response.data.prices.length > 0) {
                        response.data.prices.forEach(item => {
                        prices[item.key] = item.cheapestPrice;
                        });
                    }
                    if (response && response.data && response.data.message) {
                    console.error(`Error fetching Price Link Block prices from ${url} : ${response.data.message}`);
                    }
                }).catch((error) => {
                    console.error(`Error fetching Price Link Block prices from ${url} : ${error}`);
                })

            payload.items.forEach(item => {
                if(prices[HelperFunctions.cleanUrl(item.exposedResultsKey)]) {
                    item.price = prices[HelperFunctions.cleanUrl(item.exposedResultsKey)]
                } else {
                    item.price = 'NA'
                }
                exposedResults.items.push(item)

                promises.push(axios.head(`${checkHost}/en-gb/results/holidays${item.exposedResultsKey}`)
                    .then(resp => {
                        if (resp.status === 200) {
                            // page found, do nothing
                        }
                    })
                    .catch((error) => {
                        console.error(`Error checking exposed results key ${item.exposedResultsKey}: ${error}`)
                        // not found, remove from exposedResults
                        exposedResults.items.forEach((resultsItem, id) => {
                            if(item.exposedResultsKey === resultsItem.exposedResultsKey) {
                                delete exposedResults.items[id]
                            }
                        })
                    }))
            })

            return Promise.all(promises).then(() => {
                commit('setPriceLinkBlock', {id: payload.id, exposedResults: exposedResults })
                dispatch('setCheapestPriceLinkBlockPrice', { exposedResults: exposedResults })
            })
        },
        setCheapestPriceLinkBlockPrice: ({ rootState }, payload) => {
            payload.exposedResults.items.forEach(item => {
                if (item.price !== 'NA' && (rootState.exposedResults.cheapestPrice === null || item.price < rootState.exposedResults.cheapestPrice)) {
                    Vue.set(rootState.exposedResults, 'cheapestPrice', item.price)
                }
            });
        }

    }
}