export default {
    Accordion: () => import( /* webpackChunkName: "tsm-accordion" */ '@/components/Accordion.vue'),
    AccordionNoAds: () => import( /* webpackChunkName: "tsm-accordion-no-ads" */ '@/components/AccordionNoAds.vue'),
    Adslot: () => import(/* webpackChunkName: "tsm-adslot" */'@/components/Adslot.vue'),
    AdviceInspiration: () => import(/* webpackChunkName: "tsm-advice-inspiration" */'@/components/AdviceInspiration.vue'),
    AtolText: () => import(/* webpackChunkName: "tsm-atol-text" */'@/components/AtolText.vue'),
    Banner: () => import( /* webpackChunkName: "pref-banner" */ '@/components/Banner.vue'),
    BasicCtaGadget: () => import(/* webpackChunkName: "pref-basic-cta-gadget" */'@/components/BasicCtaGadget.vue'),
    Biography: () => import( /* webpackChunkName: "tsm-biography" */ '@/components/Biography.vue'),
    Blog: () => import(/* webpackChunkName: "tsm-blog" */'@/components/Blog.vue'),
    Blogpage: () => import(/* webpackChunkName: "tsm-blogpage" */'@/components/Blogpage.vue'),
    BlogSvg: () => import(/* webpackChunkName: "tsm-blog-svg" */"@/components/svg/tabs/blog.vue"),
    BlogV2: () => import(/* webpackChunkName: "tsm-blog" */'@/components/BlogV2.vue'),
    BreadcrumbsV2: () => import(/* webpackChunkName: "tsm-breadcrumbs-v2" */'@/components/BreadcrumbsV2.vue'),
    CampaignBannerV2: () => import( /* webpackChunkName: "tsm-campaign-banner-v2" */ "@/components/CampaignBannerV2.vue"),
    CampaignBannerHero: () => import( /* webpackChunkName: "tsm-campaign-banner-hero" */ "@/components/CampaignBannerHero.vue"),
    CarHireDeals: () => import(/* webpackChunkName: "tsm-carhire-deals" */"@/components/CarHireDeals.vue"),
    CarHireSvg: () => import(/* webpackChunkName: "tsm-car-hire" */"@/components/svg/tabs/carHire.vue"),
    CarHireGadget: () => import(/* webpackChunkName: "pref-car-hire-gadget" */'@/components/CarHireGadget.vue'),
    CmaInfo: () => import(/* webpackChunkName: "tsm-cma-info" */'@/components/CmaInfo.vue'),
    Chevron: () => import(/* webpackChunkName: "tsm-chevron" */'@/components/svg/chevron.vue'),
    Column: () => import( /* webpackChunkName: "tsm-column" */ '@/components/Column.vue'),
    CottageSvg: () => import(/* webpackChunkName: "tsm-cottage" */"@/components/svg/tabs/cottage.vue"),
    CrmModal: () => import(/* webpackChunkName: "tsm-crmmodal" */'@/components/CrmModal.vue'),
    CrossSell: () => import(/* webpackChunkName: "tsm-crosssell" */'@/components/CrossSell.vue'),
    DestinationFinderSignpost: () => import(/* webpackChunkName: "tsm-destination-finder-signpost" */'@/components/DestinationFinderSignpost.vue'),
    Eat: () => import(/* webpackChunkName: "tsm-eat" */'@/components/Eat.vue'),
    EatV2: () => import(/* webpackChunkName: "tsm-eat-v2" */'@/components/EatV2.vue'),
    EatHolidays: () => import(/* webpackChunkName: "tsm-eat-holidays" */'@/components/EatHolidays.vue'),
    EatCarhire: () => import(/* webpackChunkName: "tsm-eat-carhire" */'@/components/EatCarhire.vue'),
    EmailInterstitial: () => import( /* webpackChunkName: "tsm-email-interstitial" */ "@/components/EmailInterstitial.vue"),
    ExposedLinks: () => import(/* webpackChunkName: "tsm-exposed-links" */'@/components/ExposedLinks.vue'),
    ExposedResults: () => import(/* webpackChunkName: "tsm-exposed-results" */'@/components/ExposedResults.vue'),
    ExposedResultBlocks: () => import(/* webpackChunkName: "tsm-exposed-result-blocks" */'@/components/ExposedResultBlocks.vue'),
    Faqs: () => import( /* webpackChunkName: "tsm-faqs" */ '@/components/Faqs.vue'),
    FlightsGadget: () => import(/* webpackChunkName: "pref-flights-gadget" */ "@/components/FlightsGadget.vue"),
    FooterV2: () => import(/* webpackChunkName: "tsm-footer-v2" */'@/components/FooterV2.vue'),
    Hero: () => import(/* webpackChunkName: "tsm-hero" */'@/components/Hero.vue'),
    HeroV2: () => import(/* webpackChunkName: "tsm-hero-v2" */'@/components/HeroV2.vue'),
    HolidayInfo: () => import(/* webpackChunkName: "tsm-holiday-info" */'@/components/HolidayInfo.vue'),
    HolidayDealsV3: () => import(/* webpackChunkName: "tsm-holiday-deals-v3" */'@/components/HolidayDealsV3.vue'),
    HolidayDealsV3Deal: () => import(/* webpackChunkName: "tsm-holiday-deals-v3-deal" */'@/components/HolidayDealsV3/HolidayDealsV3Deal.vue'),
    HolidayDealsV3Interstitial: () => import( /* webpackChunkName: "tsm-email-interstitial" */ "@/components/HolidayDealsV3Interstitial.vue"),
    HolidaysGadget: () => import(/* webpackChunkName: "pref-holidays-gadget" */'@/components/HolidaysGadget.vue'),
    HotelsGadget: () => import(/* webpackChunkName: "pref-hotels-gadget" */ "@/components/HotelsGadget.vue"),
    HotelSvg: () => import(/* webpackChunkName: "tsm-hotel" */"@/components/svg/tabs/hotel.vue"),
    Icon: () => import(/* webpackChunkName: "tsm-icon" */"@/components/Icon.vue"),
    IconLibrary: () => import(/* webpackChunkName: "tsm-icon-library" */"@/components/IconLibrary.vue"),
    IframeContainer: () => import( /* webpackChunkName: "tsm-iframe-container" */ "@/components/IframeContainer.vue"),
    ImageComponent: () => import( /* webpackChunkName: "tsm-image-component" */ "@/components/ImageComponent.vue"),
    Interstitial: () => import( /* webpackChunkName: "tsm-interstitial" */ "@/components/Interstitial.vue"),
    JumpLinks: () => import(/* webpackChunkName: "tsm-sitemap" */'@/components/JumpLinks.vue'),
    Listings: () => import(/* webpackChunkName: "tsm-listings" */'@/components/Listings.vue'),
    Links: () => import(/* webpackChunkName: "tsm-links" */'@/components/Links.vue'),
    NavigationHeaderV2: () => import(/* webpackChunkName: "tsm-navigationHeaderV2" */'@/components/NavigationHeaderV2.vue'),
    Overlay: () => import(/* webpackChunkName: "tsm-overlay" */'@/components/Overlay.vue'),
    PlaneSvg: () => import(/* webpackChunkName: "tsm-plane" */"@/components/svg/tabs/plane.vue"),
    PlusSvg: () => import(/* webpackChunkName: "tsm-plus" */"@/components/svg/tabs/plus.vue"),
    Pins: () => import(/* webpackChunkName: "tsm-pins" */"@/components/Pins.vue"),
    PopularDestinations: () => import(/* webpackChunkName: "tsm-popular-destinations" */'@/components/PopularDestinations.vue'),
    PopularDestinationsV2: () => import(/* webpackChunkName: "tsm-popular-destinations-v2" */'@/components/PopularDestinationsV2.vue'),
    PriceLinkBlock: () => import(/* webpackChunkName: "tsm-price-link-block" */'@/components/PriceLinkBlock.vue'),
    Qas: () => import( /* webpackChunkName: "tsm-qas" */ "@/components/Qas.vue"),
    ReadMore: () => import(/* webpackChunkName: "tsm-readmore" */'@/components/ReadMore.vue'),
    Signup: () => import(/* webpackChunkName: "tsm-signup" */'@/components/signup/Signup.vue'),
    Slider: () => import(/* webpackChunkName: "tsm-slider" */'@/components/slider/Slider.vue'),
    SvgDefs: () => import( /* webpackChunkName: "tsm-svg-defs" */ "@/components/svg/SvgDefs.vue"),
    Swatch: () => import(/* webpackChunkName: "tsm-swatch" */'@/components/Swatch.vue'),
    TabbedComponents: () => import(/* webpackChunkName: "tsm-tabbed-components" */'@/components/TabbedComponents.vue'),
    TabbedGadgets: () => import(/* webpackChunkName: "tsm-tabbed-gadgets" */'@/components/TabbedGadgets.vue'),
    Tabs: () => import(/* webpackChunkName: "tsm-tabs" */'@/components/Tabs.vue'),
    TextSection: () => import( /* webpackChunkName: "tsm-text-section" */ "@/components/TextSection.vue"),
    TravelInsuranceSvg: () => import(/* webpackChunkName: "tsm-travel-insurance" */"@/components/svg/tabs/travelInsurance.vue"),
    TrustLogos: () => import(/* webpackChunkName: "tsm-trust-logos" */'@/components/TrustLogos.vue'),
    TrustLogosV2: () => import(/* webpackChunkName: "tsm-trust-logos-v2" */'@/components/TrustLogosV2.vue'),
    VillasGadget: () => import(/* webpackChunkName: "pref-villas-gadget" */ "@/components/VillasGadget.vue"),
    WhatSvg: () => import(/* webpackChunkName: "tsm-what" */"@/components/svg/tabs/what.vue"),
    WhenSvg: () => import(/* webpackChunkName: "tsm-when" */"@/components/svg/tabs/when.vue"),
    WhereSvg: () => import(/* webpackChunkName: "tsm-where" */"@/components/svg/tabs/where.vue"),
    WhySvg: () => import(/* webpackChunkName: "tsm-why" */"@/components/svg/tabs/why.vue"),
    Tiles: () => import(/* webpackChunkName: "tsm-tiles" */"@/components/Tiles.vue")
}
