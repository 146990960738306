/* ISC License (ISC). Copyright 2017 Michal Zalecki */
/* Inspired by: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/ */
export function storageProxy(storage) {
  const length = 0;
  function isSupported() {
    try {
      const testKey = "random_key_to_test_storage_support_in_browser";
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    }
    catch (e) {
      return false;
    }
  }
  function getItem(name) {
    if (isSupported()) {
      return storage.getItem(name);
    }
    return null;
  }
  function key(index) {
    if (isSupported()) {
      return storage.key(index);
    }
  }
  function removeItem(name) {
    if (isSupported()) {
      storage.removeItem(name);
    }
  }
  function setItem(name, value) {
    if (isSupported()) {
      storage.setItem(name, value);
    }
  }
  return {
    isSupported,
    getItem,
    setItem,
    removeItem,
    key,
    length,
  };
}
