class ResultsUrlGenerator {
  constructor() {
    this.baseUrl = `/en-gb/car-hire/results`
  }

  generate(gadgetData) {
    return `${this.baseUrl}/${gadgetData.pickUpLocation.id}/${this._differentPickUpDropOff(gadgetData)}/${gadgetData.pickUpDateTimeString}/${gadgetData.dropOffDateTimeString}/${gadgetData.driverAge}`
  }

  _differentPickUpDropOff(gadgetData) {
    if (!gadgetData.returnToSameLocation && gadgetData.dropOffLocation && gadgetData.dropOffLocation.id !== gadgetData.pickUpLocation.id) {
      return gadgetData.dropOffLocation.id
    }
    
    return gadgetData.pickUpLocation.id
  }

}

const resultsUrlGenerator = new ResultsUrlGenerator()
export default resultsUrlGenerator
